import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import IntroCopy from "../components/IntroCopy";
import ImageGallery from "../components/ImageGallery";
import ContactForm from "../components/ContactForm";

const ContactPage = ({ data }) => {
  return (
    <Layout showContactForm={false}>
      <main>
        <SEO title="Contact" />
        <IntroCopy
          headline="Contact us for a free estimate"
          body="Looking for a Roofer in Bucks? Call us on 07738 664418, 01844 347455 or using the email form below."
          topPadding
        />
        <ImageGallery
          images={data.images.nodes.filter(image => image.name === "1")}
        />
        <ContactForm alwaysOpen />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query ContactImages {
    images: allFile(filter: { relativeDirectory: { eq: "contact" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default ContactPage;
